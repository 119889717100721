export const profile = {
    color: {
        opleiding: {
            heroBg: 'from-primary-50 dark:from-primary-900/40',
            button: 'primary',
            ring: 'ring-primary-100',
            text: 'text-primary-400 dark:text-primary-500'
        },
        onderwijsinstelling: {
            heroBg: 'from-tertiary-100 dark:from-tertiary-900/50',
            button: 'tertiary',
            ring: 'ring-tertiary-100',
            text: 'text-tertiary-400 dark:text-tertiary-500'
        },
        regioloket: {
            heroBg: 'from-aux2-50 dark:from-aux2-900/40',
            button: 'aux2',
            ring: 'ring-aux2-100',
            text: 'text-aux2-400 dark:text-aux2-500'
        },
        school: {
            heroBg: 'from-secondary-50 dark:from-secondary-900/20',
            button: 'secondary',
            ring: 'ring-secondary-100',
            text: 'text-secondary-400 dark:text-secondary-500'
        },
    }
} as const

export const profileDivider = {
    color: {
        primary: {
            text: 'text-primary-500 dark:text-primary-400'
        },
        secondary: {
            text: 'text-secondary-500 dark:text-secondary-400'
        },
        tertiary: {
            text: 'text-tertiary-500 dark:text-tertiary-400'
        },
        aux1: {
            text: 'text-aux1-500 dark:text-aux1-400'
        },
        aux2: {
            text: 'text-aux2-500 dark:text-aux2-400'
        },
        aux3: {
           text: 'text-aux3-500 dark:text-aux3-400'
        }
    }
} as const


export const gradientText = {
    from: {
        default: 'from-aux1-500 dark:from-aux1-200',
    },
    to: {
        default: 'to-primary-500 dark:to-primary-200'
    }
}